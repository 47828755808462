<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Payment Status</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Payment Status Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import modulePaymentStatusManagement from "@/store/payment-status-management/modulePaymentStatusManagement.js";

export default {
  name: "PaymentStatusEdit",

  metaInfo: {
    title: "Edit Payment Status",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  data: () => ({
    payload: {
      name: ""
    }
  }),

  methods: {
    getData () {
      this.$store.dispatch('paymentStatusManagement/fetchPaymentStatus', this.decryptedID)
                 .then(({data: res}) => {
                   this.payload.name = res.data.name
                 })
                 .catch(err => this.$catchErrorResponse(err))
                 .finally(() => {
                   this.$vs.loading.close()
                 })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.append('_method', 'PUT')

      this.$store
        .dispatch("paymentStatusManagement/updatePaymentStatus", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/payment-statuses");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!modulePaymentStatusManagement.isRegistered) {
      this.$store.registerModule("paymentStatusManagement", modulePaymentStatusManagement);
      modulePaymentStatusManagement.isRegistered = true;
    }

    this.getData()
  }
};
</script>

<style></style>
